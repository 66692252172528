import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, Image, Box } from '@chakra-ui/react'

/* **************************************
 *  LOGO COMPONENT
 * ************************************ */

const styles = {
	position: 'absolute',
	transform: 'translateY(-50%)',
	top: '50%',
	pt: '0.25rem',
}

const Logo = ({ target, imagePath, altValue }) => {
	if (target) {
		return (
			<Box sx={styles}>
				<Link href={target}>
					<Image src={imagePath} alt={altValue} />
				</Link>
			</Box>
		)
	} else {
		return (
			<Box sx={styles}>
				<Image src={imagePath} alt={altValue} />
			</Box>
		)
	}
}

Logo.propTypes = {
	/** href of the logo target */
	target: PropTypes.string,
	/** path to the logo image */
	imagePath: PropTypes.string,
	/** Alternative value */
	altValue: PropTypes.string,
}

Logo.defaultProps = {
	imagePath: 'https://static.vidday.com/logos/vidday_logo.svg',
	altValue: 'Special Occasions Video Gifts & Messages: VidDay',
}

export default Logo
